import styled from "styled-components";

export const H1Styled = styled.h1`

color: #ffffff;
font-family: 'unbounded', Arial, Helvetica, sans-serif;
font-size: 5rem;
font-weight: bold;
max-width: 900px;
`

export const H2Styled = styled.h2`

color: #ffffff;
font-family: 'unbounded', Arial, Helvetica, sans-serif;
font-size: 31px;
width: 1500px;

`