import styled from "styled-components";


export const ButtonDefault = styled.a`

width: 100%;
color: #ffffff;
font-size: 28px;
background-color: transparent;
padding: 15px;
display: block;
font-weight: bold;
text-transform: uppercase;
border: 2px solid #ffffff;
text-align: center;
cursor: pointer;
&:hover {
    color: #F61067;
    background-color: #ffffff;
    
}
&:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

`

export const ProjectsButton = styled(ButtonDefault)`

background-color: #D34062;
&:hover, :active {
    color: #ffffff;
    background-color: transparent;
    border: 2px solid #D34062;
    filter: drop-shadow(0 0 0.3rem #D34062);
}
`

export const BotButton = styled(ButtonDefault)`

background-color: #D34062;

&:hover, :active {
    color: #ffffff;
    background-color: transparent;
    border: 2px solid #D34062;
    filter: drop-shadow(0 0 0.3rem #D34062);
}
`

export const LangSwitchBtn = styled(ButtonDefault)`

align-self: flex-end;
width: 157px;

padding: 10px;
margin-bottom: 67px;
text-transform: uppercase;

@media (max-width: 768px) {
    align-self: center;
}
`