import styled from "styled-components";

export const HomepageMainContainer = styled.div`
  font-family: 'unbounded', Arial, Helvetica, sans-serif;
 
  height: 100%;
  min-height: 100vh;
  width: 100%; /* Заставит фон покрывать весь сайт */
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  color: #ffffff;
  padding: 0 90px;
  
`

export const HeadersContainer = styled.div`

display: flex;
flex-direction: column;
justify-content: center;
align-items: flex-start;
font-family: 'unbounded', Arial, Helvetica, sans-serif;
gap: 15px;
margin-bottom: 30px;
max-width: 80%;
@media (max-width: 1024px) {
  max-width: 100%;
}
`

export const ButtonsMainContainer = styled.div`


width: 60%;
display: grid;
grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
&>a:nth-child(1) {
  grid-column: 1 / span 2;
}
gap: 45px;
@media (max-width: 1024px) {
    gap: 25px;
    width: 100%;
    grid-template-columns: repeat(auto-fit, 100%);
    &>a:nth-child(1) {
    grid-column: 1 / span 1;
}
}


`

export const LogoContainer = styled.div`

display: flex;
gap: 10%;

width: 100%;
justify-content: flex-start;
margin-bottom: 7%;
@media (max-width: 700px) {
  justify-content: center;
}
`

export const LogoAndLangContainer = styled.div`
padding-top: 50px;
width: 100%;
display: flex;
justify-content: space-between;
@media (max-width: 768px) {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


`

export const ButtonsAndHeadersContainer = styled.div`

display: flex;
flex-direction: column;
gap: 30px;
margin-bottom: 30px;

`
export const QRContainer = styled.div`

display: flex;
justify-content: flex-end;
margin-bottom: 30px;

`